import sfondoHeaderImage from "../assets/pexels-976866.png";
import { BackButton } from "../components/UI/BackButton";
import SearchBlue from "../assets/icons/searchBlue.svg";
import { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import doveDormireIcon from "../assets/icons/doveDormire.svg";
import doveMangiareIcon from "../assets/icons/doveMangiare.svg";
import shoppingIcon from "../assets/icons/shopping.svg";
import wineIcon from "../assets/icons/wine.svg";
import { useMediaQuery } from "react-responsive";
import { BackButtonWhite } from "../components/UI/BackButtonWhite";

import { useTranslation } from "react-i18next";

export const OspitalitaESapori = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    const currentLanguage = localStorage.getItem("i18nextLng")?.toLowerCase();
    axios
      .get(
        `https://backendstage.monreale.bbsitalia.com/${currentLanguage}/api/pagina-sezione/846`
      )
      .then((response) => setData(response.data[0]))
      .catch((error) => console.log(error));
  }, []);

  console.log(data);
  const isDesktop = useMediaQuery({
    query: "(min-width: 1024px)",
  });

  const { t } = useTranslation();

  return (
    <section
      className={!!isDesktop && `h-screen overflow-hidden`}
      id={"ospitalitaESapori"}
    >
      <div
        className={`flex flex-wrap h-[28vh] content-center relative flex overflow-hidden`}
      >
        {data && data.immagine_anteprima !== null ? (
          <img
            src={`https://backendstage.monreale.bbsitalia.com/${data.immagine_anteprima}`}
            alt={"sfondoHeaderImage"}
            className={"object-cover w-full"}
          />
        ) : (
          <div className={"bg-gray-100 h-64"} />
        )}
        <div className="absolute inset-0 bg-black bg-opacity-60 flex justify-start items-center pl-8 pr-8">
          {isDesktop ? (
            <div className="flex flex-wrap items-center w-full">
              {/*<BackButton sfondo={'true'}/>*/}
              {data && data.titolo && (
                <h1 className="text-white font-open-sans text-[55px] font-semibold w-full text-center">
                  {data.titolo}
                </h1>
              )}
              {data && data.body && data.body.length > 0 && (
                <p
                  className="text-white text-center text-[25px] w-full"
                  dangerouslySetInnerHTML={{ __html: data.body }}
                ></p>
              )}
            </div>
          ) : (
            <div className="flex flex-wrap items-start">
              <BackButton sfondo={"true"} />
              <div className="flex flex-col ml-4">
                {data && data.titolo && (
                  <h1 className="text-white font-open-sans text-[25px] font-semibold mb-10">
                    {data.titolo}
                  </h1>
                )}
              </div>
              {data && data.body && data.body.length > 0 && (
                <p
                  className="text-white text-left w-full"
                  dangerouslySetInnerHTML={{ __html: data.body }}
                ></p>
              )}
            </div>
          )}
        </div>
      </div>

      {isDesktop ? (
        <div
          className={
            "flex flex-wrap justify-center items-center mt-0 py-5 px-8 h-[62vh] overflow-y-auto"
          }
        >
          <Link to={"/dove-dormire"} className={"w-full mb-10 block"}>
            <div
              className={
                "flex flex-wrap justify-start items-center shadow-monrealeShadow px-5 py-10 rounded-[10px]"
              }
            >
              <img
                src={doveDormireIcon}
                alt={"placeholder"}
                className={"w-32 m-auto mb-10"}
              />
              <span
                className={
                  "text-monrealeRed font-roboto_slab text-[35px] font-bold w-full"
                }
              >
                Dove dormire
              </span>
            </div>
          </Link>

          <Link to={"/dove-mangiare"} className={"w-full mb-10 block"}>
            <div
              className={
                "flex flex-wrap justify-start items-center shadow-monrealeShadow px-5 py-10 rounded-[10px]"
              }
            >
              <img
                src={doveMangiareIcon}
                alt={"placeholder"}
                className={"w-32 m-auto mb-10"}
              />
              <span
                className={
                  "text-monrealeRed font-roboto_slab text-[35px] font-bold w-full"
                }
              >
                Dove mangiare
              </span>
            </div>
          </Link>

          <Link to={"/prodotti-tipici"} className={"w-full mb-10 block"}>
            <div
              className={
                "flex flex-wrap justify-start items-center shadow-monrealeShadow px-5 py-10 rounded-[10px]"
              }
            >
              <img
                src={shoppingIcon}
                alt={"placeholder"}
                className={"w-32 m-auto mb-10"}
              />
              <span
                className={
                  "text-monrealeRed font-roboto_slab text-[35px] font-bold w-full"
                }
              >
                {t("prodottitipici")}
              </span>
            </div>
          </Link>
          <Link to={"/vini-e-cantine"} className={"w-full mb-10 block"}>
            <div
              className={
                "flex flex-wrap justify-start items-center shadow-monrealeShadow px-5 py-10 rounded-[10px]"
              }
            >
              <img
                src={wineIcon}
                alt={"placeholder"}
                className={"w-32 m-auto mb-10"}
              />
              <span
                className={
                  "text-monrealeRed font-roboto_slab text-[35px] font-bold w-full"
                }
              >
                {t("viniecantine")}
              </span>
            </div>
          </Link>
        </div>
      ) : (
        <div
          className={
            "flex flex-wrap justify-center items-center mt-10 py-5 px-8"
          }
        >
          <Link to={"/dove-dormire"} className={"w-full mb-8"}>
            <div
              className={
                "flex flex-wrap justify-start items-center shadow-monrealeShadow px-5 py-10 rounded-[10px]"
              }
            >
              <img
                src={doveDormireIcon}
                alt={"placeholder"}
                className={"mr-8"}
              />
              <span
                className={
                  "text-monrealeRed font-roboto_slab text-[20px] font-bold"
                }
              >
                {t("dovedormire")}
              </span>
            </div>
          </Link>

          <Link to={"/dove-mangiare"} className={"w-full mb-8"}>
            <div
              className={
                "flex flex-wrap justify-start items-center shadow-monrealeShadow px-5 py-10 rounded-[10px]"
              }
            >
              <img
                src={doveMangiareIcon}
                alt={"placeholder"}
                className={"mr-8"}
              />
              <span
                className={
                  "text-monrealeRed font-roboto_slab text-[20px] font-bold"
                }
              >
                {t("dovemangiare")}
              </span>
            </div>
          </Link>

          <Link to={"/prodotti-tipici"} className={"w-full mb-8"}>
            <div
              className={
                "flex flex-wrap justify-start items-center shadow-monrealeShadow px-5 py-10 rounded-[10px]"
              }
            >
              <img src={shoppingIcon} alt={"placeholder"} className={"mr-8"} />
              <span
                className={
                  "text-monrealeRed font-roboto_slab text-[20px] font-bold"
                }
              >
                {t("prodottitipici")}
              </span>
            </div>
          </Link>
          <Link to={"/vini-e-cantine"} className={"w-full"}>
            <div
              className={
                "flex flex-wrap justify-start items-center shadow-monrealeShadow px-5 py-10 rounded-[10px]"
              }
            >
              <img
                src={wineIcon}
                alt={"placeholder"}
                className={"mr-8"}
                style={{
                  width: "40px",
                  marginLeft: "1rem",
                }}
              />
              <span
                className={
                  "text-monrealeRed font-roboto_slab text-[20px] font-bold"
                }
              >
                {t("viniecantine")}
              </span>
            </div>
          </Link>
        </div>
      )}

      {isDesktop ? (
        <div
          className={
            "bg-monrealeRed h-[10vh] b-t-l-r-15 content-center flex flex-wrap items-center p-8"
          }
        >
          <BackButtonWhite sfondo={"true"} />
        </div>
      ) : (
        <div className={"hidden"} />
      )}
    </section>
  );
};
