import { useEffect, useState } from "react";
import axios from "../api";
import { Link } from "react-router-dom";
import backButtonImage from "../../assets/icons/backButtonImage.svg";
import serviziWhite from "../../assets/icons/serviziWhite.svg";
import numeriUtili from "../../assets/numeri_utili.png";
import comeArrivare from "../../assets/come_arrivare.png";
import BarLoader from "react-spinners/BarLoader";
import { useMediaQuery } from "react-responsive";
import VisibileConMonrealeCard from "../UI/VisibileConMonrealeCard";
import { t } from "i18next";
import { BackButton } from "../UI/BackButton";

export const ServiziCard = () => {
  const currentLanguage = localStorage.getItem("i18nextLng")?.toLowerCase();
  const [data, setData] = useState([]);
  const [serviziList, setServiziList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState("");
  const pageSize = 10; // numero di elementi per pagina
  const eventsPerPage = 50; // limite massimo di eventi da recuperare per ogni chiamata all'API
  const [hasMoreData, setHasMoreData] = useState(true);

  const isDesktop = useMediaQuery({
    query: "(min-width: 1024px)",
  });
  const getTassonomiaServizi = () => {
    axios
      .get(
        `https://backendstage.monreale.bbsitalia.com/${currentLanguage}/api/tassonomia/servizi`
      )
      .then((response) => {
        setServiziList(response.data);
      })
      .catch((error) => console.log(error));
  };
  useEffect(() => {
    getTassonomiaServizi();
  }, []);

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(
        `https://backendstage.monreale.bbsitalia.com/${currentLanguage}/api/servizi?page=${currentPage}&pageSize=${pageSize}`
      )
      .then((response) => {
        setData((prevData) => [...prevData, ...response.data.rows]);
        setTotalPages(response.data.pager.total_pages);
        if (currentPage === response.data.pager.total_pages) {
          setHasMoreData(false);
        }
        setIsLoading(false);
      })
      .catch((error) => console.log(error));
  }, [currentPage, currentLanguage, pageSize]);

  async function fetchAllEvents() {
    const allEvents = [];
    const response = await axios.get(
      `https://backendstage.monreale.bbsitalia.com/${currentLanguage}/api/servizi?page=1&pageSize=1`
    );
    const totalEvents = response.data.pager.total_items;
    const maxEventsToFetch = Math.min(eventsPerPage, totalEvents - data.length);
    const maxPagesToFetch = Math.ceil(maxEventsToFetch / pageSize);
    for (let i = 0; i <= maxPagesToFetch; i++) {
      const response = await axios.get(
        `https://backendstage.monreale.bbsitalia.com/${currentLanguage}/api/servizi?page=${i}&pageSize=${pageSize}`
      );
      allEvents.push(...response.data.rows);
    }
    return allEvents;
  }

  useEffect(() => {
    if (hasMoreData && data.length < eventsPerPage) {
      setIsLoading(true);
      fetchAllEvents().then((allEvents) => {
        setData(allEvents);
        const totalEvents = allEvents.length;
        setHasMoreData(totalEvents < totalEvents);
        setIsLoading(false);
      });
    }
  }, [data.length, eventsPerPage, hasMoreData, currentLanguage]);

  return (
    <>
      <div className="w-full">
        {!!selectedCategory && (
          <div className="flex flex-wrap items-center w-full p-2">
            <img
              src={backButtonImage}
              alt={"backButtonImage"}
              className={"w-8 h-8"}
              onClick={() => setSelectedCategory("")}
            />
            <div className="flex flex-col ">
              <h1 className="text-monrealeRed font-open-sans text-[20px] font-semibold ml-4">
                {
                  serviziList.find(
                    (servizi) => servizi.tid === selectedCategory
                  ).nome
                }
              </h1>
            </div>
            <div></div>
          </div>
        )}
      </div>
      <div className={"flex flex-wrap overflow-auto h-full pb-5"}>
        {!selectedCategory ? (
          <CategorieServizi
            serviziList={serviziList}
            setSelectedCategory={setSelectedCategory}
          />
        ) : (
          <>
            {data
              .filter(
                (servizi) =>
                  servizi.tipologia_servizi_tid[0] === selectedCategory
              )
              .map((servizi, i) => (
                <div
                  key={servizi.tid}
                  className={
                    "flex flex-wrap basis-1/2 lg:basis-1/3 rounded-[10px] p-2 mb-5"
                  }
                >
                  <Link to={`/servizi/${servizi.id}`} className={"w-full"}>
                    <div
                      className={
                        "shadow-monrealeShadow rounded-[10px] w-full relative h-full"
                      }
                      style={{
                        maxHeight: 360,
                      }}
                    >
                      <div
                        className={
                          "absolute bg-monrealeMarkerBlu top-0 right-0 p-2 rounded-[10px]"
                        }
                      >
                        {isDesktop ? (
                          <img
                            src={serviziWhite}
                            className={"                            w-10 h-10"}
                          />
                        ) : (
                          <img
                            src={serviziWhite}
                            className={"                            w-6 h-6"}
                          />
                        )}
                      </div>
                      <img
                        src={`https://backendstage.monreale.bbsitalia.com${servizi.immagine_anteprima}`}
                        className={
                          "w-full h-[250px] lg:h-[28rem] object-cover rounded-[10px]"
                        }
                      />
                      <div
                        className={
                          "flex flex-wrap justify-center items-center px-4 py-2"
                        }
                      >
                        {isDesktop ? (
                          <h1
                            className={
                              "text-monrealeRed text-[25px] font-roboto_slab font-bold pt-3 pb-3"
                            }
                          >
                            {servizi.titolo}
                          </h1>
                        ) : (
                          <h1
                            className={
                              "text-monrealeRed text-[15px] font-roboto_slab font-bold pt-3 pb-3"
                            }
                          >
                            {servizi.titolo}
                          </h1>
                        )}
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
          </>
        )}
      </div>
    </>
  );
};

const CategorieServizi = ({ serviziList, setSelectedCategory }) => {
  const isDesktop = useMediaQuery({
    query: "(min-width: 1024px)",
  });
  return (
    <>
      <div
        className={
          "flex flex-wrap basis-1/2 lg:basis-1/3 rounded-[10px] p-2 mb-5"
        }
      >
        <Link to={`/numeri-utili`} className={"w-full"}>
          <div
            className={
              "shadow-monrealeShadow rounded-[10px] w-full relative h-full"
            }
          >
            <div
              className={
                "absolute bg-monrealeMarkerBlu top-0 right-0 p-2 rounded-[10px]"
              }
            >
              {isDesktop ? (
                <img src={serviziWhite} className={"w-10 h-10"} />
              ) : (
                <img src={serviziWhite} className={"w-6 h-6"} />
              )}
            </div>
            <img
              src={numeriUtili}
              className={
                "w-full h-[250px] lg:h-[28rem] object-contain rounded-[10px]"
              }
              style={{
                padding: 48,
              }}
            />
            <div
              className={"flex flex-wrap justify-center items-center px-4 py-2"}
            >
              {isDesktop ? (
                <h1
                  className={
                    "text-monrealeRed text-[25px] font-roboto_slab font-bold pt-3 pb-3"
                  }
                >
                  {t("numeriUtili")}
                </h1>
              ) : (
                <h1
                  className={
                    "text-monrealeRed text-[15px] font-roboto_slab font-bold pt-3 pb-3"
                  }
                >
                  {t("numeriUtili")}
                </h1>
              )}
            </div>
          </div>
        </Link>
      </div>
      <div
        className={
          "flex flex-wrap basis-1/2 lg:basis-1/3 rounded-[10px] p-2 mb-5"
        }
      >
        <Link to={`/come-arrivare`} className={"w-full"}>
          <div
            className={
              "shadow-monrealeShadow rounded-[10px] w-full relative h-full"
            }
          >
            <div
              className={
                "absolute bg-monrealeMarkerBlu top-0 right-0 p-2 rounded-[10px]"
              }
            >
              {isDesktop ? (
                <img
                  src={serviziWhite}
                  className={"                            w-10 h-10"}
                />
              ) : (
                <img
                  src={serviziWhite}
                  className={"                            w-6 h-6"}
                />
              )}
            </div>
            <img
              src={comeArrivare}
              className={
                "w-full h-[250px] lg:h-[28rem] object-contain rounded-[10px]"
              }
              style={{
                padding: 36,
              }}
            />
            <div
              className={"flex flex-wrap justify-center items-center px-4 py-2"}
            >
              {isDesktop ? (
                <h1
                  className={
                    "text-monrealeRed text-[25px] font-roboto_slab font-bold pt-3 pb-3"
                  }
                >
                  {t("comeArrivare")}
                </h1>
              ) : (
                <h1
                  className={
                    "text-monrealeRed text-[15px] font-roboto_slab font-bold pt-3 pb-3"
                  }
                >
                  {t("comeArrivare")}
                </h1>
              )}
            </div>
          </div>
        </Link>
      </div>

      {serviziList.map((servizi, i) => (
        <div
          key={servizi.tid}
          className={
            "flex flex-wrap basis-1/2 lg:basis-1/3 rounded-[10px] p-2 mb-5"
          }
          onClick={() => setSelectedCategory(servizi.tid)}
        >
          <div
            className={
              "shadow-monrealeShadow rounded-[10px] w-full relative h-full"
            }
          >
            <div
              className={
                "absolute bg-monrealeMarkerBlu top-0 right-0 p-2 rounded-[10px]"
              }
            >
              {isDesktop ? (
                <img
                  src={serviziWhite}
                  className={"                            w-10 h-10"}
                />
              ) : (
                <img
                  src={serviziWhite}
                  className={"                            w-6 h-6"}
                />
              )}
            </div>
            <img
              src={`https://backendstage.monreale.bbsitalia.com${servizi.field_immagine_export}`}
              className={
                "w-full h-[250px] lg:h-[28rem] object-contain rounded-[10px]"
              }
              style={{
                padding: 36,
              }}
            />
            <div
              className={"flex flex-wrap justify-center items-center px-4 py-2"}
            >
              {isDesktop ? (
                <h1
                  className={
                    "text-monrealeRed text-[25px] font-roboto_slab font-bold pt-3 pb-3"
                  }
                >
                  {servizi.nome}
                </h1>
              ) : (
                <h1
                  className={
                    "text-monrealeRed text-[15px] font-roboto_slab font-bold pt-3 pb-3"
                  }
                >
                  {servizi.nome}
                </h1>
              )}
            </div>
          </div>
        </div>
      ))}
    </>
  );
};
