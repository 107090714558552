import React, { useState, useEffect, useCallback, useRef } from "react";
import monrealeLogo from "../assets/logo.png";
import axios from "axios";
import VideoPlayer from "../components/UI/VideoPlayer";

function Screensaver() {
  const [showScreensaver, setShowScreensaver] = useState(false);
  const timeoutRef = useRef();
  const [videoData, setVideoData] = useState(null);

  const resetTimeout = useCallback(() => {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => setShowScreensaver(true), 60000);
  }, []);

  useEffect(() => {
    let lastInteractionTime = Date.now();

    const handleInteraction = () => {
      if (showScreensaver) {
        lastInteractionTime = Date.now();
        setShowScreensaver(false);
      } else {
        lastInteractionTime = Date.now();
        resetTimeout();
      }
    };

    document.addEventListener("click", handleInteraction);
    document.addEventListener("touchstart", handleInteraction);

    resetTimeout();

    return () => {
      document.removeEventListener("click", handleInteraction);
      document.removeEventListener("touchstart", handleInteraction);
      clearTimeout(timeoutRef.current);
    };
  }, [resetTimeout, showScreensaver]);

  function handleScreensaverClick() {
    setShowScreensaver(false);
  }

  useEffect(() => {
    if (showScreensaver) {
      clearTimeout(timeoutRef.current);
    }
  }, [showScreensaver]);

  let lang = localStorage.getItem("i18nextLng")?.toLowerCase();

  useEffect(() => {
    axios
      .get("https://backendstage.monreale.bbsitalia.com/api/advertising-video")
      .then((response) => {
        setVideoData(response.data.rows);
        console.log("data received:", response.data);
      })
      .catch((error) => console.log(error));
  }, []);

  const handleVideoEnd = useCallback(() => {
    resetTimeout();
  }, [resetTimeout]);

  return (
    <>
      {showScreensaver && (
        <div
          className="overflow-hidden absolute top-0 left-0 w-full z-50 z-99999 h-screen"
          onClick={handleScreensaverClick}
          onTouchStart={handleScreensaverClick}
        >
          <div className={"absolute w-full text-center top-20 overflow-hidden"}>
            <img
              className={"w-[250px] h-auto m-auto relative z-10"}
              src={monrealeLogo}
              alt="Monreale Logo"
            />
          </div>
          <div
            className={
              "w-full h-full object-cover absolute top-0 left-0 z-auto"
            }
          >
            <VideoPlayer videoData={videoData} onVideoEnd={handleVideoEnd} />
          </div>
          <div
            className={
              "absolute w-full text-center bottom-20 overflow-hidden flex"
            }
          >
            <div
              className={
                "text-center w-[60%] bottom-20 overflow-hidden bg-monrealeRed rounded-[10px] m-auto block p-4"
              }
            >
              <p className={"text-white text-4xl font-bold"}>
                {lang === "it"
                  ? `Clicca lo schermo\n per utilizzare l'infopoint interattivo`
                  : `Click the screen to use the interactive infopoint`}
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Screensaver;
