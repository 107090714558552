import React, { useState, useEffect, useRef } from "react";
import { MapContainer, TileLayer, Marker, Popup, Circle } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
// import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.css';
// import 'leaflet-defaulticon-compatibility';
import axios from "../api";
import { MappaDellaCittaCard } from "./MappaDellaCittaCard";
import filterIcon from "../../assets/icons/filter.svg";
import { BackButtonWhite } from "../UI/BackButtonWhite";
import { useMediaQuery } from "react-responsive";
import { getDistance } from "geolib";

import currentPosition from "../../assets/icons/current-position.png";

import * as geolib from "geolib";
import { useTranslation } from "react-i18next";
import { MappaDellaCittaAroundCard } from "./MappaDellaCittaAroundCard";

const ToggleButtons = ({ options, selectedOption, onChange }) => (
  <div className="flex flex-wrap basis-1/2 w-full">
    {options.map((option) => (
      <button
        key={option.value}
        onClick={() => onChange(option.value)}
        className={`${
          option.value === selectedOption
            ? "bg-monrealeRed text-white shadow-monrealeShadow"
            : "bg-white text-monrealeRed border-monrealeRed border-2"
        } rounded-md px-4 py-2 mb-2 w-[47%] mr-2`}
      >
        {option.label}
      </button>
    ))}
  </div>
);

const MapComponentAroundMe = () => {
  const [locations, setLocations] = useState([]);
  const [filter, setFilter] = useState("all");
  const mapRef = useRef();
  const [bounds, setBounds] = useState(null);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [radius, setRadius] = useState(0.5); // default radius is 5 km
  const [currentLocationReceived, setCurrentLocationReceived] = useState(false);
  const [watcherId, setWatcherId] = useState(null);

  const fetchLocations = async () => {
    try {
      const response = await axios.get(
        "https://backendstage.monreale.bbsitalia.com/it/api/locations-around"
      );
      setLocations(
        response.data.filter(
          (location) =>
            location.field_togli_da_around_me_export === "0" ||
            location.field_togli_da_around_me_export === null
        )
      );
    } catch (error) {}
  };
  // fetchLocations();

  console.log(locations);

  useEffect(() => {
    const getCurrentLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setCurrentLocation({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            });
            setCurrentLocationReceived(true);
            fetchLocations(); // Sposta qui la chiamata a fetchLocations
          },
          (error) => {
            if (error.code === 1) {
              alert("La geolocalizzazione è stata disattivata.");
            } else {
              console.log(error);
            }
          }
        );
      } else {
        alert("La geolocalizzazione non è disponibile su questo dispositivo.");
      }
    };
    getCurrentLocation();
  }, []);

  const clearWatcher = () => {
    if (watcherId != null) {
      navigator.geolocation.clearWatch(watcherId);
      setWatcherId(null);
    }
  };

  useEffect(() => {
    return () => {
      clearWatcher();
    };
  }, []);

  const filteredLocations = locations
    .filter((location) => {
      // Filtraggio delle posizioni in base al tipo e alla distanza
      if (
        (filter === "all" || location.type === filter) &&
        location.latitude != null &&
        location.longitude != null &&
        currentLocation != null
      ) {
        const distance = geolib.getDistance(
          {
            latitude: currentLocation.latitude,
            longitude: currentLocation.longitude,
          },
          { latitude: location.latitude, longitude: location.longitude }
        );
        return distance <= radius * 99999999999;
      } else {
        return false;
      }
    })
    .sort((a, b) => {
      // Ordinamento delle posizioni in base alla distanza dal punto corrente
      const distanceA = geolib.getDistance(
        {
          latitude: currentLocation.latitude,
          longitude: currentLocation.longitude,
        },
        { latitude: a.latitude, longitude: a.longitude }
      );
      const distanceB = geolib.getDistance(
        {
          latitude: currentLocation.latitude,
          longitude: currentLocation.longitude,
        },
        { latitude: b.latitude, longitude: b.longitude }
      );
      return distanceA - distanceB;
    });

  useEffect(() => {
    if (mapRef.current) {
      let newBounds;
      if (filteredLocations.length > 0) {
        let minLat = filteredLocations[0].latitude;
        let maxLat = filteredLocations[0].latitude;
        let minLng = filteredLocations[0].longitude;
        let maxLng = filteredLocations[0].longitude;
        for (let i = 1; i < filteredLocations.length; i++) {
          const { latitude, longitude } = filteredLocations[i];
          if (latitude < minLat) {
            minLat = latitude;
          } else if (latitude > maxLat) {
            maxLat = latitude;
          }
          if (longitude < minLng) {
            minLng = longitude;
          } else if (longitude > maxLng) {
            maxLng = longitude;
          }
        }
        newBounds = L.latLngBounds([minLat, minLng], [maxLat, maxLng]);
        console.log("Fit to filtered locations:", filteredLocations);
        mapRef.current.fitBounds(newBounds);
      } else {
        newBounds = L.latLngBounds(
          [currentLocation.latitude, currentLocation.longitude],
          [currentLocation.latitude, currentLocation.longitude]
        );
        console.log("Fit to current location");
        mapRef.current.fitBounds(newBounds, { padding: [50, 50], maxZoom: 15 });
      }
    }
  }, [filteredLocations]);

  const getDetailLink = (location) => {
    if (!location || !location.type || !location.id) {
      return null;
    }
    let link = null;
    switch (location.type) {
      // case 'eventi':
      //     link = `/#/eventi-e-rassegne/${location.id}`;
      //     break;
      case "musei_e_cultura":
        link = `/#/musei-e-cultura/${location.id}`;
        break;
      case "punti_di_interesse":
        link = `/#/punti-di-interesse/${location.id}`;
        break;
      // case 'dove_mangiare':
      //     link = `/#/dove-mangiare/${location.id}`;
      //     break;
      // case 'dove_dormire':
      //     link = `/#/dove-dormire/${location.id}`;
      //     break;
      // case 'sapori_e_shopping':
      //     link = `/#/sapori-e-shopping/${location.id}`;
      //     break;
      // default:
      //     link = `/#/dettaglio/${location.id}`;
      //     break;
    }
    return link;
  };

  const { t } = useTranslation();
  const filterOptions = [
    { value: "all", label: `${t("tutte")}` },
    // { value: 'eventi', label: `${t('eventi')}` },
    { value: "musei_e_cultura", label: `${t("museiecultura")}` },
    { value: "punti_di_interesse", label: `${t("puntidiinteresse")}` },
    // { value: 'dove_mangiare', label: `${t('dovemangiare')}` },
    // { value: 'dove_dormire', label: `${t('dovedormire')}` },
    // { value: 'sapori_e_shopping', label: `${t('saporieshopping')}` },
  ];

  const [myLat, setMyLat] = useState(null);
  const [myLong, setMyLong] = useState(null);
  const [filterRadius, setFilterRadius] = useState(0);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      setMyLat(position.coords.latitude);
      setMyLong(position.coords.longitude);
    });
  }, []);

  useEffect(() => {
    if (mapRef.current) {
      let newZoom;
      if (currentLocationReceived) {
        const circleRadius = radius * 99999999999;
        const circleBounds = L.latLngBounds([
          [
            currentLocation.latitude - circleRadius,
            currentLocation.longitude - circleRadius,
          ],
          [
            currentLocation.latitude + circleRadius,
            currentLocation.longitude + circleRadius,
          ],
        ]);
        newZoom = mapRef.current.getBoundsZoom(circleBounds);
      } else {
        newZoom = 12;
      }
      mapRef.current.setView(
        [currentLocation.latitude, currentLocation.longitude],
        newZoom
      );
    }
  }, [radius, currentLocationReceived, currentLocation]);

  const isDesktop = useMediaQuery({
    query: "(min-width: 1024px)",
  });

  const getDistanceText = (latitude, longitude) => {
    const distance = geolib.getDistance(
      {
        latitude: currentLocation.latitude,
        longitude: currentLocation.longitude,
      },
      { latitude, longitude }
    );

    if (distance < 1000) {
      return `${distance} m`;
    } else {
      return `${(distance / 1000).toFixed(1)} km`;
    }
  };

  const locationsWithDistance = filteredLocations.map((location) => ({
    ...location,
    distance: getDistanceText(location.latitude, location.longitude),
  }));

  console.log(locationsWithDistance);

  return (
    <div className="h-60vh overflow-auto">
      <div
        className={`${
          showFilterModal ? "block" : "hidden"
        } fixed z-[9999] inset-0 overflow-y-auto`}
      >
        <div className="flex items-end justify-end flex-wrap min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div className="fixed inset-0 transition-opacity">
            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>
          <div
            className="inline-block align-middle bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full w-full bottom-0"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
            style={{
              position: "absolute",
              bottom: "100px",
              left: "50%",
              transform: "translateX(-50%)",
            }}
          >
            {/*<div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">*/}
            {/*    <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-headline">*/}
            {/*        {t('selezionaunacategoria')}*/}
            {/*    </h3>*/}
            {/*    <div className="mt-4">*/}
            {/*        <ToggleButtons options={filterOptions} selectedOption={filter} onChange={(val) => setFilter(val)} />*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div className="flex flex-wrap items-center justify-items-center content-center text-center">
              <label htmlFor="range" className="mr-2 w-full">
                Seleziona il raggio di ricerca:
              </label>
              <input
                type="range"
                id="range"
                name="range"
                min="0.5"
                max="10"
                step="0.5"
                value={radius}
                className={"m-auto mt-2 mb-2"}
                onChange={(e) => setRadius(parseFloat(e.target.value))}
              />
              <output className="ml-2 w-full">{radius} km</output>
            </div>
            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              <button
                type="button"
                onClick={() => setShowFilterModal(false)}
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-monrealeRed text-base font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
              >
                {t("chiudi")}
              </button>
            </div>
          </div>
        </div>
      </div>
      {/*{currentLocation ? (*/}
      {/*    <MapContainer center={[38.0812, 13.2894]} zoom={12} style={{ height: '25vh', width: '100%', zIndex: '0', position: 'relative' }} ref={mapRef}>*/}
      {/*    <TileLayer attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'*/}
      {/*               url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />*/}
      {/*    {filteredLocations.map((location) => (*/}
      {/*        <Marker key={location.id} position={[location.latitude, location.longitude]}>*/}
      {/*            <Popup>*/}
      {/*                <div>*/}
      {/*                    <h3>{location.title}</h3>*/}
      {/*                    <button*/}
      {/*                        onClick={() => (window.location.href = getDetailLink(location))}*/}
      {/*                        className="bg-monrealeRed text-white rounded-md px-4 py-2"*/}
      {/*                    >*/}
      {/*                        Vai alla scheda*/}
      {/*                    </button>*/}
      {/*                </div>*/}
      {/*            </Popup>*/}
      {/*        </Marker>*/}
      {/*    ))}*/}
      {/*    {filteredLocations.map((location) => (*/}
      {/*        <Marker key={location.id} position={[location.latitude, location.longitude]}>*/}
      {/*            <Popup>*/}
      {/*                <div>*/}
      {/*                    <h3>{location.title}</h3>*/}
      {/*                    <button*/}
      {/*                        onClick={() => (window.location.href = getDetailLink(location))}*/}
      {/*                        className="bg-monrealeRed text-white rounded-md px-4 py-2"*/}
      {/*                    >*/}
      {/*                        Vai alla scheda*/}
      {/*                    </button>*/}
      {/*                </div>*/}
      {/*            </Popup>*/}
      {/*        </Marker>*/}
      {/*    ))}*/}
      {/*    {filteredLocations.filter(location =>*/}
      {/*        location.latitude != null && location.longitude != null &&*/}
      {/*        distance(location.latitude, location.longitude, myLat, myLong) <= filterRadius*/}
      {/*    ).map((location) => (*/}
      {/*        <Marker key={location.id} position={[location.latitude, location.longitude]}>*/}
      {/*            <Popup>*/}
      {/*                <div>*/}
      {/*                    <h3>{location.title}</h3>*/}
      {/*                    <button*/}
      {/*                        onClick={() => (window.location.href = getDetailLink(location))}*/}
      {/*                        className="bg-monrealeRed text-white rounded-md px-4 py-2"*/}
      {/*                    >*/}
      {/*                        Vai alla scheda*/}
      {/*                    </button>*/}
      {/*                </div>*/}
      {/*            </Popup>*/}
      {/*        </Marker>*/}
      {/*    ))}*/}
      {/*    {currentLocationReceived && (*/}
      {/*        <Marker position={[currentLocation.latitude, currentLocation.longitude]} icon={L.icon({ iconUrl: currentPosition })}>*/}
      {/*            <Popup>Tu sei qui</Popup>*/}
      {/*        </Marker>*/}
      {/*    )}*/}

      {/*    {currentLocationReceived && (*/}
      {/*    <Circle center={[currentLocation.latitude, currentLocation.longitude]} radius={radius * 500} />*/}
      {/*    )}*/}

      {/*</MapContainer> ) : (*/}
      {/*    <div>Loading...</div>*/}
      {/*)}*/}
      {/*<button*/}
      {/*    onClick={() => setShowFilterModal(true)}*/}
      {/*    className=" mt-1 mb-5 text-left bg-white text-white rounded-md px-4 py-2 mb-2 flex justify-center items-center"*/}
      {/*>*/}
      {/*    <img src={filterIcon} alt="Filtro" className="w-10 h-10 mr-4" />*/}
      {/*    <span className={'text-monrealeRed font-roboto_slab text-[20px] font-bold'}>{t('filtrapercategoria')}</span>*/}
      {/*</button>*/}

      {filteredLocations.length === 0 && (
        <div>{t("nessunrisultatotrovatoattornoate")}.</div>
      )}
      <MappaDellaCittaAroundCard data={locationsWithDistance} filter={filter} />
      {isDesktop ? (
        <div
          className={
            "bg-monrealeRed h-[10vh] b-t-l-r-15 content-center flex flex-wrap items-center p-8"
          }
        >
          <BackButtonWhite sfondo={"true"} />
        </div>
      ) : (
        <div className={"hidden"} />
      )}
    </div>
  );
};

export default MapComponentAroundMe;

function distance(lat1, lon1, lat2, lon2) {
  const R = 6371; // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1); // deg2rad below
  const dLon = deg2rad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c; // Distance in km
  return d;
}

function deg2rad(deg) {
  return deg * (Math.PI / 180);
}
